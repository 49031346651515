import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { I18N, TCustomAttribute } from 'aurelia-i18n';
import { Internationalization } from './services/internationalization';

/**
 * Main Aurelia bootstrapping.
 *
 * @param aurelia - The Aurelia instance
 */
export async function configure(aurelia: Aurelia): Promise<void> {

  aurelia
    .use
    .standardConfiguration()
    .developmentLogging()
    .plugin(PLATFORM.moduleName('aurelia-portal-attribute'))
    .plugin(PLATFORM.moduleName('aurelia-i18n'), (instance: I18N) => {
      const resources = require(
        'i18next-resource-store-loader!./locales/index.js'
      );

      let aliases = ['t', 'i18n'];
      // add aliases for 't' attribute
      TCustomAttribute.configureAliases(aliases);

      // adapt options to your needs (see http://i18next.com/docs/options/)
      // make sure to return the promise of the setup method, in order to guarantee proper loading
      return instance.setup({
        attributes: aliases,
        debug : false,
        fallbackLng : 'en',
        lng : 'en',
        resources
      });
    })
    .feature(PLATFORM.moduleName('features/icons/index'))
    .feature(PLATFORM.moduleName('features/ui/index'));

  try {
    await aurelia.start();

    const internationalization: Internationalization = aurelia.container.get(Internationalization);

    await internationalization.loadLanguages();

    await aurelia.setRoot(PLATFORM.moduleName('views/root'));
    console.log('Startup successful');
  } catch (error) {
    console.log((error as Error).message);
  }
}
