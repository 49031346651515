import { I18N } from 'aurelia-i18n';
import { Logger } from './logger';
import i18next from 'i18next';
import { autoinject, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

interface IFixedT {
  language: string;
  t: i18next.TFunction;
}

@autoinject()
export class Internationalization {
  private readonly _supportedLanguages: string[] = ['en', 'nl'];
  private readonly _fixedT: IFixedT[] = [];
  private _activeLanguage: string = '';

  public constructor(private readonly _eventAggregator: EventAggregator, private readonly _i18n: I18N, private readonly _logger: Logger) {
  }

  @computedFrom('_activeLanguage', '_supportedLanguages')
  public get otherLanguages(): string[] {
    return this._supportedLanguages.filter((language: string) => { return language !== this.activeLanguage });
  }

  @computedFrom('_activeLanguage')
  public get activeLanguage(): string {
    if (this._activeLanguage === '') {
      this._activeLanguage = this._i18n.getLocale();
    }
    return this._activeLanguage;
  }

  public getAllTranslationsForKey(key: string): string[] {
    const translations: string[] = this._fixedT.map((fixedT: IFixedT): string => {
      return fixedT.t(key);
    }).filter((value: string, index: number, self: string[]): boolean => {
      return self.indexOf(value) === index;
    });

    return translations;
  }

  public getPreferredLanguage(): string {
    const preferredLanguageFromStorage: string|null = localStorage.getItem('language');
    const preferredLanguageFromHeader: string|undefined = navigator.languages.find((language: string) => {
      return this._supportedLanguages.includes(language);
    })
    const preferredLanguage: string|undefined = preferredLanguageFromStorage !== null
      ? preferredLanguageFromStorage
      : preferredLanguageFromHeader;

    return typeof preferredLanguage !== 'undefined' ? preferredLanguage : 'en';
  }

  public isLanguageSupported(language: string): boolean {
    return this._supportedLanguages.includes(language);
  }

  public async loadLanguages(): Promise<void> {
    return new Promise((resolve: () => void, reject: (reason: Error) => void): void => {
      this._i18n.i18next.loadLanguages(this._supportedLanguages, (err: unknown) => {
        if (typeof err === 'undefined' || err === null) {
          this._supportedLanguages.forEach((language: string): void => {
            this._fixedT.push({language, t: this._i18n.i18next.getFixedT(language)});
          })

          resolve();
        } else {
          this._logger.error('Error', err);

          reject(new Error('Unable to load languages'));
        }
      });
    });
  }

  public async setLanguage(language: string): Promise<void> {
    if (this.isLanguageSupported(language)) {
      try {
        await this._i18n.setLocale(language)
        this._activeLanguage = language;
        this._eventAggregator.publish('language-changed', language);
      } catch (error) {
        this._logger.error(error as Error);
      }
    } else {
      this._logger.warn('Unsupported language.');
    }
  }

  public translate(key: string, language?: string): string {
    if (typeof language === 'string' && this.isLanguageSupported(language)) {
      const result: IFixedT|undefined = this._fixedT.find((fixedT: IFixedT): boolean => {
        return fixedT.language === language;
      })

      if (typeof result !== 'undefined') {
        return result.t(key);
      }
    }

    return this._i18n.tr(key)
  }
}
