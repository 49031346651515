import { customElement, containerless, inlineView } from 'aurelia-framework';

import svg from '@fortawesome/fontawesome-free/svgs/solid/moon.svg';

@containerless()
@customElement('icon-moon')
@inlineView('<template><span innerhtml.bind="icon"></span></template>')
export class IconMoon {
  public icon: string = svg;
}
