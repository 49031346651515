import { Redirect } from 'aurelia-router';
import { inlineView, autoinject } from 'aurelia-framework';
import { Internationalization } from '../services/internationalization';

@autoinject()
@inlineView('<template></template>')
export class NoLanguage {
  public constructor(private readonly _internationalization: Internationalization) {
  }

  public canActivate(): Redirect {
    return new Redirect(`/${this._internationalization.getPreferredLanguage()}`);
  }
}
