import { inlineView, autoinject, containerless, computedFrom, customElement } from 'aurelia-framework';
import { Internationalization } from '../services/internationalization';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Router, RouterEvent, NavigationInstruction } from 'aurelia-router';

@autoinject()
@containerless()
@inlineView(`<template>
  <meta portal="target: head" name="Language" content="\${language}">
  <link portal="target: head" repeat.for="item of languages" rel="alternate" hreflang.bind="item.language" href.bind="item.href" />
</template>`)
@customElement('head-language')
export class HeadLanguage {
  private _subscriptions: Subscription[] = [];
  private _language: string = '';
  private _languages: {language: string; href: string}[] = [];

  public constructor(private readonly _eventAggregator: EventAggregator, private readonly _internationalization: Internationalization, private readonly _router: Router) {
  }

  public attached(): void {
    this._subscriptions.push(this._eventAggregator.subscribe('language-changed', (language: string) => {
      this._updateLanguage(language);
    }));
    this._subscriptions.push(this._eventAggregator.subscribe(RouterEvent.Success, (event: unknown) => {
      this._updateLanguages();
    }))
    this._updateLanguage(this._internationalization.activeLanguage);
  }

  public detached(): void {
    this._subscriptions.forEach((subscription: Subscription) => { subscription.dispose(); });
    this._subscriptions = [];
  }

  @computedFrom('_language')
  public get language(): string {
    return this._language;
  }

  @computedFrom('_languages')
  public get languages(): {language: string; href: string}[] {
    return this._languages;
  }

  private _updateLanguage(language: string): void {
    this._language = language;

    document.documentElement.setAttribute('lang', language);
  }

  private _updateLanguages(): void {
    const instructions: NavigationInstruction[] = this._router.currentInstruction.getAllInstructions();
    const url: URL = new URL(document.location.href);
    const basePath = `${url.protocol}//${url.host}/`;

    this._languages = this._internationalization.otherLanguages.map((language: string) => {
      const path: string = instructions.map((instruction: NavigationInstruction): string => {
        if (typeof instruction.config.name !== 'undefined') {
          if (instruction.config.name === 'language') {
            return language;
          }

          return this._internationalization.translate(`routes.${instruction.config.name}`, language);
        }

        return '';
      }).filter((item: string):boolean => {
        return item !== '';
      }).join('/');

      return {language, href: `${basePath}${path}`};
    });
  }
}
