import { autoinject, inlineView } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { RouterConfiguration } from 'aurelia-router';

@autoinject()
@inlineView('<template><head-language></head-language><router-view></router-view></template>', [
  PLATFORM.moduleName('../widgets/head-language')
])
export class Root {
  public configureRouter(config: RouterConfiguration): void {
    config.title = 'BBosman.dev';
    config.options.pushState = true;
    config.fallbackRoute('/');
    config.map([
      {route: '', moduleId: PLATFORM.moduleName('./nolanguage'), name: 'nolanguage'},
      {route: ':language', moduleId: PLATFORM.moduleName('./shell/shell'), name: 'language'}
    ]);
  }
}
