import { autoinject, customAttribute, computedFrom } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

@autoinject()
@customAttribute('theme-switcher')
export class ThemeSwicher {
  private _subscriptions: Subscription[] = [];
  private _matchMediaDark: MediaQueryList|undefined;
  private _matchMediaLight: MediaQueryList|undefined;
  private _watchMediaListenerDarkHandler: (event: MediaQueryListEvent) => void = this._watchMediaListenerDark.bind(this);
  private _watchMediaListenerLightHandler: (event: MediaQueryListEvent) => void = this._watchMediaListenerLight.bind(this);

  public constructor(private readonly _eventAggregator: EventAggregator) {
  }

  public bind(): void {
    this._subscriptions.push(this._eventAggregator.subscribe('switch-theme', (darkMode: boolean) => {
      this._setMode(darkMode);
    }));

    this._matchMediaDark = window.matchMedia("(prefers-color-scheme: dark)");
    this._matchMediaLight = window.matchMedia("(prefers-color-scheme: light)");
    this._matchMediaDark.addListener(this._watchMediaListenerDarkHandler);
    this._matchMediaLight.addListener(this._watchMediaListenerLightHandler);

    this._setMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
  }

  public unbind(): void {
    this._subscriptions.forEach((subscription: Subscription) => { subscription.dispose(); });
    this._subscriptions = [];

    this._matchMediaDark?.removeListener(this._watchMediaListenerDarkHandler);
    this._matchMediaLight?.removeListener(this._watchMediaListenerLightHandler);

    this._matchMediaDark = undefined;
    this._matchMediaLight = undefined;
  }

  private _setMode(dark: boolean) {
    const mode: string = dark ? 'dark' : 'light';

    this._eventAggregator.publish('switched-theme', dark);

    document.documentElement.setAttribute('data-theme', mode);
  }

  private _watchMediaListenerDark(event: MediaQueryListEvent): void {
    this._setMode(event.matches);
  }

  private _watchMediaListenerLight(event: MediaQueryListEvent): void {
    this._setMode(!event.matches);
  }
}
