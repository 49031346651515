import { Redirect, RouterConfiguration, activationStrategy } from 'aurelia-router';
import { autoinject, PLATFORM, computedFrom } from 'aurelia-framework';
import { Internationalization } from '../../services/internationalization';
import { IActivateArguments } from '../../types';
import { Logger } from '../../services/logger';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

interface IActivateArgumentsShell extends IActivateArguments {
  language: string;
}

@autoinject()
export class Shell {
  private _darkMode: boolean = window.matchMedia("(prefers-color-scheme: dark)").matches;
  private _subscriptions: Subscription[] = [];

  public constructor(private readonly _eventAggregator: EventAggregator, private readonly _internationalization: Internationalization, private readonly _logger: Logger) {
  }

  @computedFrom('_darkMode')
  public get darkMode(): boolean {
    return this._darkMode;
  }

  public attached(): void {
    this._subscriptions.push(this._eventAggregator.subscribe('switched-theme', (dark: boolean) => {
      this._darkMode = dark;
    }));
  }

  public detached(): void {
    this._subscriptions.forEach((subscription: Subscription) => { subscription.dispose(); });
    this._subscriptions = [];
  }

  public async canActivate(args: IActivateArgumentsShell): Promise<Redirect|boolean> {
    if (!this._internationalization.isLanguageSupported(args.language)) {
      return new Redirect(this._internationalization.getPreferredLanguage());
    }

    try {
      await this._internationalization.setLanguage(args.language);
    } catch(error) {
      this._logger.error(error as Error);

      return false;
    }

    return true;
  }

  public configureRouter(config: RouterConfiguration): void {
    config.map([
      {route: '', moduleId: PLATFORM.moduleName('../home/home'), name: 'home'}
      // {route: this._internationalization.getAllTranslationsForKey('routes.public'), moduleId: PLATFORM.moduleName('./public/public'), name: 'public', settings: {loggedIn: false}},
      // {route: this._internationalization.getAllTranslationsForKey('routes.private'), moduleId: PLATFORM.moduleName('./private/private'), name: 'private', settings: {loggedIn: true}},
    ]);
  }

  public determineActivationStrategy(): string {
    return activationStrategy.replace;
  }

  public setMode(dark: boolean): void {
    this._eventAggregator.publish('switch-theme', dark);
  }
}
