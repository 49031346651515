import { customElement, containerless, inlineView } from 'aurelia-framework';

import svg from '@fortawesome/fontawesome-free/svgs/solid/sun.svg';

@containerless()
@customElement('icon-sun')
@inlineView('<template><span innerhtml.bind="icon"></span></template>')
export class IconSun {
  public icon: string = svg;
}
