import * as AureliaLogging from 'aurelia-logging';
import { autoinject } from 'aurelia-framework';

@autoinject()
export class Logger {
  private readonly _logger: AureliaLogging.Logger;

  public constructor() {
    this._logger = AureliaLogging.getLogger('BBosmanDev');
  }

  public debug(message: string, ...rest: any[]): void {
    this._logger.debug(message, ...rest);
  }
  public error(error: string|Error, ...rest: any[]): void {
    this._logger.error(typeof error === 'string' ? error : error.message, ...rest);
  }

  public info(message: string, ...rest: any[]): void {
    this._logger.info(message, ...rest);
  }

  public warn(message: string, ...rest: any[]): void {
    this._logger.warn(message, ...rest);
  }
}
