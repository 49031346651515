import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class Home {
  public jumbotron!: HTMLDivElement

  private _updateSizeHandler: () => void = this._updateSize.bind(this);

  public constructor(private readonly _eventAggregator: EventAggregator) {
  }

  public attached() {
    window.addEventListener('resize', this._updateSizeHandler, false);

    this._updateSize();
  }

  public darkModeChanged(newValue: boolean) {
    this._eventAggregator.publish('switch-theme', newValue);
  }

  public detached() {
    window.removeEventListener('resize', this._updateSizeHandler);
  }

  private _updateSize(): void {
    this.jumbotron.style.height = `${window.innerHeight}px`;
  }
}
